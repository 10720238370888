






















import { Component, Vue } from 'vue-property-decorator';
import { PortalModule } from '@/store/modules/portal';
import {commonDataTab} from '@/assets/data/common';
import i18n from '@/lang'
@Component({
  name: 'headerLayout',
})
export default class extends Vue {
    // 获取tab
    get setCommonDataTab(){
        return commonDataTab();
    }
    // 获取检索值
    get getSearchValue(){
        return this.$store.state.portal.searchValue;
    }
    // 是否显示子级
    get getShowChild(){
        return this.$store.state.portal.showChild;
    }
    // 点击展示介绍
    public checkshow:boolean=false;
    // 高亮的名称
    public tabName:any='';
    private mounted(){
        this.tabActive();
    }
    // 高亮
    public tabActive(){
        const tab:any=this.$route.query.tab;
        if(!tab){
            this.tabName='product'
        }else{
            this.tabName=tab;
        }
    }
    // 点击
    public handleTab(key:any){
        const queryTab:any=this.$route.query.tab;
        if(queryTab!==key){
            this.tabName=key;
            this.$router.push({query:{'tab':key}})
        }
    }
    // 展示介绍改变
    public changeShow(){
        this.checkshow=!this.checkshow;
        PortalModule.SetIntroduce(this.checkshow);
    }
}
