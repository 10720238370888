import Vue from 'vue';
import ElementUI from 'element-ui';
import i18n from '@/lang';
import '@/assets/style/element-variables.scss';
// 全局控制Dialog点击背景层默认值为false
(ElementUI.Dialog as any).props.closeOnClickModal.default=false;
// 全局控制Input输入框能删除内容
(ElementUI.Input as any).props.clearable.default=true

Vue.use(ElementUI, {
  i18n: (key: string, value: string) => i18n.t(key, value),
});