import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './utils/element_ui';
import '@/assets/style/index.scss';
import i18n from '@/lang';
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
