import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { getLocale } from '@/lang';
import {setLanguage} from '@/utils/local_storage';

export interface PortalState {
    searchValue:string // 检索值
    showIntroduce:boolean // 展示介绍
    count:number // 每页多少条
    showParent:boolean // 显示父级
    showChild:boolean// 显示子级
    detail:string// 详情内容
    itemIndex:any// 当前下标
    language: string;// 语言
}
@Module({ dynamic: true, store, name: 'portal' })
class Portal extends VuexModule implements PortalState {
    public searchValue:string='';
    public showIntroduce:boolean=false;
    public count:number=16;
    public showParent:boolean=true;
    public showChild:boolean=false;
    public detail:string='';
    public itemIndex:any=null;
    public language=getLocale();

    // 调用赋值检索
    @Action
    public SetSearchValue(val: string){
        this.SET_SEARCH(val);
    }
    // 调用赋值展示介绍变化
    @Action
    public SetIntroduce(val:boolean){
        this.SET_INTRODUCE(val);
    }
    // 调用显示父级
    @Action
    public SetShowParent(val:boolean){
        this.SET_SHOW_PARENT(val);
    }
    // 调用显示子级
    @Action
    public SetShowChild(val:boolean){
        this.SET_SHOW_CHILD(val);
    }
    // 调用详情内容
    @Action
    public SetDetail(val: string){
        this.SET_DETAIL(val);
    }
    // 调用下标
    @Action
    public SetItemIndex(index:any){
        this.SET_ITEM_INDEX(index)
    }
    // 调用语言
    @Action
    public SetLanguage(language: string) {
        this.SET_LANGUAGE(language);
    }

    // 赋值检索
    @Mutation
    private SET_SEARCH(val:string){
        this.searchValue=val;
    }
    // 展示介绍
    @Mutation
    private SET_INTRODUCE(val:boolean){
        this.showIntroduce=val;
    }
    // 显示父级、
    @Mutation
    private SET_SHOW_PARENT(val:boolean){
        this.showParent=val;
    }
    // 显示子级
    @Mutation
    private SET_SHOW_CHILD(val:boolean){
        this.showChild=val;
    }
    // 详情内容
    @Mutation
    private SET_DETAIL(val:string){
        this.detail=val;
    }
    // 设置下标
    @Mutation
    private SET_ITEM_INDEX(index:any){
        this.itemIndex=index;
    }
    // 设置语言
    @Mutation
    private SET_LANGUAGE(language: string) {
        this.language = language;
        setLanguage(this.language);
    }
}
export const PortalModule = getModule(Portal);