import i18n from '@/lang';
// 语言切换
export function commonDataLanguage(){
    return [
        {key:'zh-cn',value:i18n.t('portal.chinese')},
        {key:'en',value:i18n.t('portal.english')}
    ]
}
// 底部tab
export function commonDataTab(){
    return [
        {key:'product',value:i18n.t('portal.product')},
        {key:'dev',value:i18n.t('portal.dev')}
    ]
}