






import { Vue , Component } from 'vue-property-decorator';
import AppMain from './components/app_main.vue';
@Component({
  name: 'Layout',
  components: { AppMain },
})
export default class extends Vue {


}
