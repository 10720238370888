export default{
    search:'搜索',
    switchLanguage:'语言切换',
    chinese:'中文',
    english:'EN',
    product:'产品中心',
    dev:'研发中心',
    showIntroduce:'展示介绍',
    showIntroduceSmall:'详情',
    notData:'暂无结果',
    yse:'是',
    no:'否',
    tip:'提示',
    downloadTencentMeeting:'是否已经下载了腾讯会议?',
};