export default{
    search:'Search',
    switchLanguage:'Language',
    chinese:'中文',
    english:'EN',
    product:'Product',
    dev:'Dev',
    showIntroduce:'Show',
    showIntroduceSmall:'Show',
    notData:'No Data',
    yse:'Yes',
    no:'No',
    tip:'Tip',
    downloadTencentMeeting:'Has Tencent conference been downloaded?',
};