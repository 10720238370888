import Vue from 'vue';
import VueI18n from 'vue-i18n';

import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';

import enLocale from './en/index';
import zhLocale from './zh/index';
import { getLanguage, setLanguage } from '@/utils/local_storage';

Vue.use(VueI18n);

const messages = {
  'en': {
    ...enLocale,
    ...elementEnLocale,
  },
  'zh-cn': {
    ...zhLocale,
    ...elementZhLocale,
  },
};

export const getLocale = () => {
  const cookieLanguage = getLanguage();
  if (cookieLanguage) {
    return cookieLanguage;
  }
  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      setLanguage(locale);
      return locale;
    }
  }

  return 'zh-cn';
};

const i18n = new VueI18n({
  locale: getLocale(),
  messages,
});

export default i18n;
