

























import { Component, Vue } from 'vue-property-decorator';
import { PortalModule } from '@/store/modules/portal';
import { setLanguage,getLanguage } from '@/utils/local_storage';
import {commonDataLanguage} from '@/assets/data/common'
import i18n from '@/lang'
@Component({
  name: 'headerLayout',
})
export default class extends Vue {
    // 获取语言类型
    get getCommonDataLanguage(){
        return commonDataLanguage();
    }
    // 检索内容
    public searchValue:any='';
    // 语言
    public languageValue:any='';

    public mounted(){
        const language=getLanguage();
        if(language){
            this.languageValue=language
        }
    }
    // 检索
    public handleSearch(){
        PortalModule.SetSearchValue(this.searchValue);
    }
    // 设置语言
    public languageChange(e: any){
        PortalModule.SetLanguage(e);
        setLanguage(e);
        i18n.locale=e;
    }
}
